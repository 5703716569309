export const generic_hazards = {
    "arctic": [
        "At initiative count 20 (losing ties), the frigid air crystallizes, requiring Dexterity saving throws to avoid taking slashing damage from ice shards that materialize and fly through the air.",
        "At the end of a player's turn, if they moved more than 10 feet, icy illusions distort vision, requiring Wisdom saving throws to avoid being disoriented and attacking allies.",
        "When creatures emit bright light sources, blinding reflections occur, requiring Dexterity saving throws to avoid being blinded and suffering disadvantage on attacks.",
        "At initiative count 20 (losing ties), an arctic vortex forms, requiring Strength saving throws to avoid being pulled in and restrained by the powerful winds.",
        "When creatures make sudden movements, the icy ground becomes unstable, requiring Dexterity saving throws to avoid slipping and being knocked prone.",
        "At the end of a player's turn, if they remain in one place, frostbitten apparitions haunt the area, requiring Wisdom saving throws to avoid being frightened and having disadvantage on attacks.",
        "When creatures touch certain frozen surfaces, the cold seeps into their veins, requiring Constitution saving throws to avoid taking cold damage and potentially gaining vulnerability to cold damage for 1 minute.",
        "At initiative count 20 (losing ties), the air becomes so thin that it affects the mind, requiring Constitution saving throws to resist taking psychic damage and potentially being stunned for 1 turn.",
        "When creatures emit strong heat sources, the ice melts beneath them, requiring Dexterity saving throws to avoid falling into freezing water and taking cold damage.",
        "At the end of a player's turn, if they moved less than 5 feet, a spectral blizzard engulfs the area, requiring Intelligence saving throws to avoid being blinded and suffering disadvantage on attacks.",
        "When creatures emit strong vibrations, dangerous cracks form in the ice, requiring Dexterity saving throws to avoid falling through and taking falling damage.",
        "At initiative count 20 (losing ties), haunting whispers on the icy wind affect the mind, requiring Wisdom saving throws to resist taking psychic damage and potentially being frightened for 1 turn.",
        "When creatures create loud noises, the ice vibrates with dangerous resonance, requiring Constitution saving throws to avoid taking thunder damage and potentially being deafened.",
        "At the end of a player's turn, if they remain in one place, jagged ice spikes emerge from the ground, requiring Strength saving throws to break free and avoid being restrained.",
    ],
    "coastal": [
        "At initiative count 20, a sudden tide surge occurs, forcing creatures to make Strength saving throws to avoid being knocked prone and pulled toward the sea.",
        "When a creature casts a spell, a magical storm responds with a bolt of lightning, requiring Dexterity saving throws from creatures near the caster to avoid taking lightning damage.",
        "If a creature doesn't move on their turn, burrowing crustaceans strike, requiring Dexterity saving throws to avoid being pinched or grappled, taking piercing damage.",
        "At the end of a player's turn, if they moved more than 5 feet, treacherous quicksand patches may be triggered, requiring Strength saving throws to escape.",
        "When a creature attacks, the sandy terrain may shift, creating difficult terrain and requiring Dexterity saving throws to avoid tripping.",
        "At initiative count 20, seagulls may swoop down aggressively, requiring creatures to make Dexterity saving throws to avoid being pecked and distracted.",
        "If a creature casts a spell, the residual magical energy may disturb the underwater ecosystem, attracting curious and potentially dangerous aquatic creatures.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden underwater currents may drag creatures away, requiring Strength saving throws to resist being pulled.",
        "After a player attacks, the noises disturb the nesting grounds of coastal birds, prompting aerial attacks that require Dexterity saving throws to avoid.",
        "At initiative count 20, a sudden fog bank rolls in from the sea, obscuring vision and requiring Wisdom saving throws to navigate safely through the maritime haze."
    ],
    "desert": [
        "At initiative count 20, a sandstorm kicks up, reducing visibility and requiring Constitution saving throws to avoid exhaustion from inhaling fine particles.",
        "When a creature casts a spell, the magical disturbance may trigger mirages, requiring an Intelligence saving throw to see them.",
        "At the start of combat, every creature is affected by the Mirror Image spell, as magical mirages appear.",
        "After a player moves more than 10 feet, hidden quicksand pits may be triggered, requiring Strength saving throws to escape.",
        "When a creature attacks, the harsh sands eat away at their weapon, reducing its attack bonus by 1 for 1 hour.",
        "At initiative count 20, a scorching heatwave descends, requiring Constitution saving throws to resist heat exhaustion and dehydration.",
        "If a creature casts a spell, the residual magical energy may disrupt the sand, creating difficult terrain around the caster.",
        "If a creature doesn't move on their turn, the desert floor may crack open, revealing treacherous chasms that require Dexterity saving throws to avoid falling in.",
        "When a creature casts a spell, the residual energies coalesce into angry Dust Mephits that disappear at the end of their turn.",
        "At initiative count 20, a sudden oasis appears, luring creatures with mirage-like illusions; creatures must make Wisdom saving throws against being charmed by the mirages."
    ],
    "forest": [
        "At initiative count 20, a dense fog rolls in, reducing visibility and requiring Wisdom saving throws to avoid becoming blinded.",
        "If a player doesn't move on their turn, a living tree swings its branch at them, requiring a Dexterity saving throw to avoid bludgeoning damage.",
        "If a creature makes a sound, the echoes may attract aggressive wildlife, leading to surprise attacks or heightened aggression.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden vines and roots may entangle their legs, requiring Strength saving throws to break free.",
        "When a creature attacks, the disturbed fauna may release toxic spores, requiring Constitution saving throws to avoid poisoning.",
        "At initiative count 20, a sudden downpour creates muddy terrain, making movement difficult and requiring Dexterity saving throws to avoid slipping.",
        "If a creature casts a spell, the residual magic may animate nearby plants, causing them to entwine and restrain creatures; Strength saving throws are needed to break free.",
        "At the end of a player's turn, if they moved more than 5 feet, disturbed beehives may release swarms of aggressive bees, requiring Dexterity saving throws to avoid stings and piercing damage.",
        "At initiative count 20, the forest canopy shakes with a sudden gust of wind, requiring Dexterity saving throws to avoid falling debris and branches.",
        "A magical plant attempts to beguile player characters, requiring a Wisdom saving throw to avoid being charmed by it."
    ],
    "grassland": [
        "At initiative count 20, a sudden gust of wind blows across the open grassland. Ranged attacks have disadvantage, as the wind veers them off course.",
        "After a creature finishes moving, they disturb a nest of burrowing mites, which attack the creature.",
        "When a creature attacks, the vibrations may attract a stampede of herbivores, requiring Dexterity saving throws to avoid being trampled and taking bludgeoning damage.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden sinkholes may collapse, requiring Dexterity saving throws to avoid falling.",
        "If a creature casts a spell, the residual magic may disturb the local fauna, causing them to become aggressive and attack.",
        "At initiative count 20, a sudden rain shower creates muddy terrain, making movement difficult and requiring Dexterity saving throws to avoid slipping.",
        "When a creature makes a sound, it may disturb a swarm of territorial insects, resulting in attacks that require Dexterity saving throws to evade.",
        "After a creature moves 10 feet, hidden thorns and brambles may entangle their legs, requiring a Dexterity saving throw to avoid being restrained.",
        "If a creature attacks, the sudden movement may disturb a hidden nest of venomous snakes, requiring Constitution saving throws to resist poison.",
        "At initiative count 20, a swarm of biting insects emerges, imposing disadvantage on concentration checks and dealing damage to any creature that begins its turn inside the swarm."
    ],
    "hill": [
        "At initiative count 20, a sudden rockslide occurs, creating difficult terrain and requiring Dexterity saving throws to avoid being struck by falling debris and taking bludgeoning damage.",
        "If a creature makes a sound, it may attract the attention of territorial birds of prey, causing them to swoop down and attack.",
        "When a creature attacks, the vibrations may disturb a colony of aggressive insects, which attack the creature.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden crevices may open up, requiring Dexterity saving throws to avoid falling.",
        "If a creature casts a spell, the residual magical energy may trigger localized tremors, causing instability and requiring Strength saving throws to remain upright.",
        "At initiative count 20, a sudden gust of wind sweeps across the hillside, causing ranged attacks to veer off course and miss.",
        "When a creature makes a sound, it may disturb a nest of territorial burrowers, causing them to emerge and attack; Dexterity saving throws are required to avoid bites.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden thorns and brambles may entangle their legs, requiring Strength saving throws to break free.",
        "After a creature moves 30 feet on its turn, the sudden movement may disturb a hidden nest of venomous snakes, requiring Constitution saving throws to resist poison.",
        "At initiative count 20, a swarm of stinging insects emerges, creating a distraction that imposes disadvantage on concentration checks for spellcasting."
    ],
    "mountain": [
        "At initiative count 20, a sudden avalanche occurs, creating difficult terrain and requiring Dexterity saving throws to avoid being buried.",
        "When a creature casts a spell, it may trigger a rockslide, causing falling debris and requiring Dexterity saving throws to avoid taking damage.",
        "When a creature casts a spell, a nearby stormcloud throws a bolt of lightning at a random creature, which must make a Dexterity saving throw to avoid lightning damage.",
        "At the end of a player's turn, if they moved more than 5 feet, unstable terrain may give way, requiring Dexterity saving throws to avoid falling.",
        "If a creature casts a spell, the magical energy may resonate with the mountain's core, causing tremors and requiring Strength saving throws to remain balanced.",
        "At initiative count 20, sudden gusts of wind may buffet the mountain, giving disadvantage to ranged attacks.",
        "When a creature makes a sound, it may attract the attention of territorial eagles, causing them to dive-bomb.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden crevasses may open up, requiring Dexterity saving throws to avoid falling.",
        "At the end of a player's turn, a distant giant hurls a boulder their way, requiring a dexterity saving throw to avoid massive bludgeoning damage.",
        "At initiative count 20, a sudden storm rolls in, reducing visibility and imposing disadvantage on ranged attacks."
    ]
    ,
    "swamp": [
        "If a creature doesn't move on its turn, they must make a Dexterity saving throw to avoid being restrained by quicksand.",
        "At initiative count 20, sudden dense fog may roll in, reducing visibility and imposing disadvantage on ranged attacks.",
        "If a creature makes a sound, it may attract the attention of territorial giant mosquitoes, causing them to swarm and impose disadvantage on attacks.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden quicksand pits may give way, requiring Strength saving throws to avoid sinking.",
        "When a creature casts a spell, the magical energy may disturb a cluster of toxic plants, requiring Constitution saving throws against being poisoned.",
        "At initiative count 20, sudden torrential rain may flood the area, turning the ground into difficult terrain.",
        "When a creature begins moving, plants become thorny, dealing damage for each 5 feet a creature moves through them that turn.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden sinkholes may open up, requiring Dexterity saving throws to avoid falling.",
        "After a creature casts a spell, spectral lights cloud their vision, requiring a Wisdom saving throw against being frightened.",
        "Whenever an attack or spell that deals fire damage is used or cast, noxious gas catches light, dealing the damage to all creatures within 20 feet of the target."
    ],
    "underdark": [
        "When a creature casts a spell, it may disturb a nest of cave-dwelling bats, causing them to swarm and impose disadvantage on attacks for one round",
        "At initiative count 20, sudden darkness may envelop the area, imposing disadvantage on perception checks and affecting creatures with a Bane spell.",
        "If a creature moves 30 feet on its turn, they trigger underground tremors, creating difficult terrain and requiring Dexterity saving throws to avoid falling prone.",
        "At the end of a player's turn, if they moved more than 5 feet, hidden crevices may open up, requiring Dexterity saving throws to avoid falling.",
        "When a creature casts a spell, the magical energy may awaken dormant fungal spores, releasing poisonous gas and requiring Constitution saving throws.",
        "At initiative count 20, sudden gusts of underground winds may blow through, extinguishing open flames and making ranged attacks difficult.",
        "At the end of a player's turn, shadows encroach on them, and they must make a Wisdom saving throw or become frightened of their shadow.",
        "At initiative count 20, Shadows rise from the bodies of any creatures slain in combat.",
        "When a creature makes a sound, it may trigger a cave-in, causing falling debris and requiring Strength saving throws to avoid being buried.",
        "At initiative count 20, sudden bioluminescent fungi may flare up, blinding creatures temporarily and imposing disadvantage on attacks."
    ],
    "underwater": [
        "At initiative count 20 (losing ties), an unseen force creates whirlpools, requiring Dexterity saving throws to avoid being pulled in and restrained.",
        "When creatures move quickly, the water temperature drops suddenly, requiring Constitution saving throws to avoid taking cold damage and being affected by the Slow spell.",
        "When creatures create bright light sources, deep-sea shadows converge, requiring Wisdom saving throws to avoid being frightened.",
        "At initiative count 20 (losing ties), an ancient curse awakens, requiring Charisma saving throws to resist suffering the effects of a bane spell.",
        "When a creature makes a sudden movement, it may disturb a school of aggressive territorial fish, provoking attacks that require Dexterity saving throws to evade.",
        "At initiative count 20, a powerful underwater current may sweep through the area, making swimming difficult and requiring Strength saving throws to resist being pushed away.",
        "When a creature attacks, the sudden movement may disturb an electrically charged jellyfish swarm, causing electric shocks that require Constitution saving throws to resist paralysis.",
        "At initiative count 20, a bioluminescent distraction may occur, attracting the attention of predatory underwater creatures and imposing disadvantage on Stealth checks.",
        "At the end of a player's turn, if they moved more than 5 feet, they may unintentionally awaken a dormant underwater lava vent, releasing a burst of scalding hot water and requiring a Dexterity saving throw to avoid fire damage.",
        "When a creature casts a spell, the magical energy may resonate with magical currents, causing random effects (roll on the Wild Magic table)",
        "At initiative count 20, a sudden drop in temperature may occur, creating icy conditions that impose disadvantage on attack rolls and Dexterity saving throws."


    ],
    "urban": [],
    "other": [],
    "undead": [],
    "fiend": [],
    "aberration": []
}